import { type AxiosError } from 'axios';

import { type TOptional } from '@/types/common';

export type TCaught = {
  error: AxiosError;
  isCaught: boolean;
};

const withApiErrorCatcher =
  <A extends unknown[], R, C extends unknown[]>(
    apiCall: (...args: A) => Promise<R>,
    errorCatcher: (error: AxiosError, ...args: C) => Promise<TOptional<boolean>>,
    ...catcherArgs: C
  ) =>
  async (...apiArgs: A): Promise<R | TCaught> => {
    try {
      return await apiCall(...apiArgs);
    } catch (err) {
      const error = err as AxiosError;
      if (error?.isAxiosError && (await errorCatcher(error, ...catcherArgs))) return { error, isCaught: true };
      throw error;
    }
  };

export default withApiErrorCatcher;
