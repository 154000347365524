import { api } from '@/helpers/api/core';
import { type TISODate } from '@/infra/types/common';
import { type TUserSubscriptionSource } from '@/infra/types/user';

export type TSubscribeArgs = Partial<TUserSubscriptionSource> & {
  dateOfBirth: TISODate;
  email: string;
  firstName: string;
  isOptInForEmail: boolean;
  isOptInForSMS: boolean;
  lastName: string;
  notificationTypeCode: string;
  phoneCountryCode: `${number}`;
  phoneNumber: `${number}`;
};

export const subscribe = (payload: TSubscribeArgs) => api.post('/subscribe', payload);
