import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';

import type { CruiseCommonSlice, Package } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';
import type { AppDispatch, AppGetState, RootState } from '@/store';

import { selectAccessKeyPromoCode } from '@/ducks/accessKeys/accessKeys';
import { checkAccessKeyAction } from '@/ducks/accessKeys/actions';
import { selectBookingSourcePayload } from '@/ducks/api/selectors';
import { selectDefaultPackageCodes } from '@/ducks/common/lookup/selectors';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { type SettingsSlice } from '@/ducks/common/settings';
import {
  selectCabinType,
  selectCurrencyCode,
  selectFromDate,
  selectMetaType,
  selectSailors,
  selectToDate,
  selectVoyageIds,
} from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { fetchVoyageListData } from '@/helpers/api/app';
import isSuitableCabinType from '@/helpers/isSuitableCabinType';
import { checkForValueType } from '@/helpers/util/misc';
import { FiltersSortType } from '@/infra/types/common/filters';

import {
  NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
  NEW_CHOOSE_VOYAGE_SEARCH_FAILURE,
  NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
  NEW_CHOOSE_VOYAGE_SET_LOADING,
  NEW_GENERIC_CATEGORY_CODE_DATA,
} from '../actionTypes';
import { getSailingsFromPackages } from '../getters';
import { getFilteredPackages, selectFetchPayload, selectIsMultipleVoyageFilterActive } from '../selectors';
import { sortSailings } from '../utility';

const getDefaultPayload = (state: RootState) => {
  const filterOptions = getFiltersOptions(state);
  const currencyCode = selectDefaultCurrencyCode(state);
  const defaultPackageCodes = selectDefaultPackageCodes(state);
  return {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: filterOptions.defaultFilters.sailors,
            },
          ],
        },
      ],
      currencyCode,
      defaultPackageCodes,
      preferences: [],
      sailingDateRange: [
        {
          end: filterOptions.defaultEndDate,
          start: filterOptions.defaultStartDate,
        },
      ],
    },
  };
};

export const buildFetchVoyageListDataPayload = (
  props: { accessKeys?: string[]; dateFrom?: string; dateTo?: string } = {},
  state: RootState,
) => {
  const metaType = selectMetaType(state);
  const cabinType = selectCabinType(state);
  const dateFrom = props.dateFrom || selectFromDate(state);
  const dateTo = props.dateTo || selectToDate(state);
  const sailors = selectSailors(state);
  const currencyCode = selectCurrencyCode(state);
  const defaultPackageCodes = selectDefaultPackageCodes(state) || [];

  let preferencesValue: { categoryCode?: string; metaCode?: string }[] = [];

  if (isSuitableCabinType(cabinType)) {
    preferencesValue = [{ categoryCode: cabinType }];
  } else if (metaType) {
    preferencesValue = [{ metaCode: metaType }];
  }

  const payload = {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: props.accessKeys || [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: sailors,
            },
          ],
        },
      ],
      currencyCode,
      defaultPackageCodes,
      isDefaultSearch: false,
      preferences: preferencesValue,
      sailingDateRange: [
        {
          end: dateTo,
          start: dateFrom,
        },
      ],
    },
    ...selectBookingSourcePayload(state),
  };

  if (isEqual(payload, getDefaultPayload(state))) {
    payload.searchQualifier.isDefaultSearch = true;
  }

  return payload;
};

export const fetchPackages =
  (config: { headers?: Record<string, string>; settings?: SettingsSlice } = {}) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    try {
      const cabinType = selectCabinType(state);
      const metaType = selectMetaType(state);
      const isMultipleVoyageFilterActive = selectIsMultipleVoyageFilterActive(state);
      const voyageIds = selectVoyageIds(state);

      const accessKey = selectAccessKeyPromoCode(state) || (await dispatch(checkAccessKeyAction(config)))?.promoCode;

      const fetchPayload = buildFetchVoyageListDataPayload(accessKey ? { accessKeys: [accessKey] } : {}, state);

      if (isEqual(fetchPayload, selectFetchPayload(state))) {
        return;
      }

      dispatch({
        payload: true,
        type: NEW_CHOOSE_VOYAGE_SET_LOADING,
      });

      const results = (await fetchVoyageListData(fetchPayload, config)) as CruiseCommonSlice['packagesData'];

      if (results.defaultPackages) {
        results.defaultPackages = sortSailings(results.defaultPackages, FiltersSortType.date) as Package[];
      }

      if (checkForValueType(results)) {
        const { packages } = results;
        const sailings = getSailingsFromPackages(packages);
        if (checkForValueType(sailings)) {
          results.sailings = sailings;
        }
      }

      if (cabinType === null || !isEmpty(metaType)) {
        dispatch({
          payload: {
            defaultGenericCategoryCodes: results.defaultGenericCategoryCodes,
            genericCategoryCodes: results.genericCategoryCodes,
          },
          type: NEW_GENERIC_CATEGORY_CODE_DATA,
        });
      }

      if (results && results.packages) {
        const filteredSailings = isMultipleVoyageFilterActive
          ? getSailingsFromPackages(results?.packages).filter((sailing) => voyageIds.indexOf(sailing.id) > -1)
          : results.sailings;
        const filteredResults = {
          ...results,
          fetchPayload,
          sailings: filteredSailings,
        };

        dispatch({
          payload: filteredResults,
          type: NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
        });
      }

      dispatch(filterPackages());

      dispatch({
        payload: false,
        type: NEW_CHOOSE_VOYAGE_SET_LOADING,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        payload: true,
        type: NEW_CHOOSE_VOYAGE_SEARCH_FAILURE,
      });
    }
  };

export const filterPackages = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const filteredPackages = getFilteredPackages(getState());
  const data: { packages: Package[]; sailings?: SailingFromPackages[] } = { packages: filteredPackages };
  const sailings = getSailingsFromPackages(filteredPackages);
  if (sailings.length) {
    data.sailings = sailings;
  }

  dispatch({ payload: data, type: NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS });
};
