import { selectLookup } from '@/ducks/common/selectors';
import { selectProfileEmailNotifications } from '@/ducks/profile/selectors';
import { selectPrimarySailorWithInsurance } from '@/ducks/travelParty/selectors';
import { subscribe } from '@/features/api/endpoints/user';
import { getCountryDialCode } from '@/helpers/util';
import { isVWOFeatureEnabled } from '@/helpers/vwo/isFeatureEnabled';
import { VWOFlag } from '@/types/vwo';

export const subscribeForNewsAction = (primarySailor, subscriptionSource) => async (dispatch, getState) => {
  const {
    add_newsletter: addNewsletter,
    contactnumber: phoneNumber,
    dob: dateOfBirth,
    email,
    firstname: firstName,
    isOptInForEmailRadio,
    isOptInForSMS,
    lastname: lastName,
    phoneCountryCode,
  } = primarySailor || {};
  const state = getState();
  const prevPrimarySailor = selectPrimarySailorWithInsurance(state);
  const notifications = selectProfileEmailNotifications(state);
  const lookup = selectLookup(state);
  const isEmailOptInEnabled = await isVWOFeatureEnabled(VWOFlag.EMAIL_OPT_IN_RADIO);
  const canSkip =
    isEmailOptInEnabled && isOptInForEmailRadio === undefined && notifications?.every((item) => item.IsOptInForEmail);

  if (canSkip) return;

  const isSubScribeForNewsToBeCalledForcely =
    prevPrimarySailor?.email &&
    (prevPrimarySailor?.isOptInForSMS !== isOptInForSMS ||
      prevPrimarySailor?.isOptInForEmailRadio !== isOptInForEmailRadio ||
      prevPrimarySailor?.add_newsletter !== addNewsletter);

  if (addNewsletter || isOptInForEmailRadio !== undefined || isOptInForSMS || isSubScribeForNewsToBeCalledForcely) {
    return subscribe({
      ...subscriptionSource,
      dateOfBirth,
      email,
      firstName,
      isOptInForEmail: addNewsletter || isOptInForEmailRadio || false,
      isOptInForSMS: isOptInForSMS || false,
      lastName,
      notificationTypeCode: 'NO',
      phoneCountryCode: getCountryDialCode(lookup, phoneCountryCode),
      phoneNumber,
    });
  }
};

export default subscribeForNewsAction;
