import { createSelector } from '@reduxjs/toolkit';

import type { TCurrencyCode } from '@/infra/types/common';
import type { RootState } from '@/store';

import { BookingChannelTypes } from '@/constants/settings';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import {
  selectAgencyId,
  selectAgentBookingChannel,
  selectAgentId,
  selectIsAgencyDataAvailable,
} from '@/ducks/fm/selectors';
import {
  selectIsMnvv,
  selectMnvvAgencyId,
  selectMnvvAgentId,
  selectMnvvChannelId,
  selectMnvvReservationNumber,
} from '@/ducks/mnvv/selectors';

const selectAgentDetailPayload = createSelector(
  [selectIsMnvv, selectMnvvAgencyId, selectMnvvAgentId, selectAgencyId, selectAgentId],
  (isMnvv, mnvvAgencyId, mnvvAgentId, agencyId, agentId) => {
    const agency = isMnvv ? mnvvAgencyId : agencyId;
    const agent = isMnvv ? mnvvAgentId : agentId;

    return {
      ...(agency && { agencyId: agency }),
      ...(agent && { agentId: agent }),
    };
  },
);

export const selectBookingSourcePayload = (
  state: RootState,
  externalCurrencyCode?: TCurrencyCode,
  isSignature = false,
  isReservation = true,
) => {
  const isMnvv = selectIsMnvv(state);
  const reservationNumber = selectMnvvReservationNumber(state);
  const mnvvChannelId = selectMnvvChannelId(state);
  const currencyCode = externalCurrencyCode || selectCurrencyCode(state);
  const isFm = selectIsAgencyDataAvailable(state);
  const agentBookingChannel = selectAgentBookingChannel(state);
  const agentDetail = selectAgentDetailPayload(state);

  return {
    ...(isMnvv && { bookingChannel: mnvvChannelId }),
    ...(isMnvv && isReservation && { reservationNumber }),
    ...(!isMnvv && isFm && { bookingChannel: agentBookingChannel || BookingChannelTypes.fmLink }),
    ...(!isMnvv && !isFm && { bookingChannel: BookingChannelTypes.openTravel }),
    ...(currencyCode && { currencyCode }),
    ...(!isSignature ? isFm && { agentDetail } : { ...agentDetail }),
  };
};
