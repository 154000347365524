import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import {
  SUMMARY_CALCULATE_INVOICE_CLEAR,
  SUMMARY_CALCULATE_INVOICE_ERROR,
  SUMMARY_CALCULATE_INVOICE_START,
  SUMMARY_CALCULATE_INVOICE_SUCCESS,
} from '@/constants/actionTypes';
import { throwError } from '@/ducks/common';
import { selectConfigServiceFeatureCelebrations } from '@/ducks/common/settings';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { navigateToChooseCabinSubMeta, routes } from '@/ducks/routes';
import { fetchCaluclateInvoiceData, fetchUserProfileData } from '@/helpers/api/app';
import { extractErrorMessage } from '@/helpers/extractErrorMessage';
import { getCabinInvoiceNumber } from '@/helpers/selectors';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';
import { CalculateInvoiceFailureCode } from '@/infra/types/api/common';

export const clearInvoiceDataAction = () => async (dispatch) => {
  dispatch({ type: SUMMARY_CALCULATE_INVOICE_CLEAR });
};

export const wrapMessage = (message /* ?:string */) /* :({ message: string } | undefined) */ =>
  message ? { message } : undefined;

export const calculateInvoiceErrorAction = (err) => async (dispatch) => {
  dispatch({
    payload: isPlainObject(err) ? err : wrapMessage(extractErrorMessage(err)) || {},
    type: SUMMARY_CALCULATE_INVOICE_ERROR,
  });
};

export const fetchCalculateInvoiceDataAction = (VoyageSearchFilters) => async (dispatch, getState) => {
  let data = {};
  const state = getState();

  try {
    dispatch(clearInvoiceDataAction());
    dispatch({
      type: SUMMARY_CALCULATE_INVOICE_START,
    });

    const promoCode = VoyageSearchFilters?.accessKey;
    const pathname = VoyageSearchFilters?.pathname;
    let externalRefId = VoyageSearchFilters?.externalRefId;
    if (!externalRefId) {
      const userDetails = localStorage.getItem('Authorization');
      if (!isEmpty(userDetails)) {
        const details = await fetchUserProfileData();
        externalRefId = details?.externalRefId;
      }
    }
    const isRouteFetch = [routes.planner.confirmation.path, routes.planner.payment.path].includes(pathname);
    const sessionInvoice = getSessionStorageValue('invoiceData');
    if (isRouteFetch && !isEmpty(sessionInvoice)) {
      data = sessionInvoice;
    } else if (!isEmpty(VoyageSearchFilters) && VoyageSearchFilters?.voyageId) {
      setSessionStorageValue('invoiceData', {});
      setSessionStorageValue('cabinNumber', '');
      data = await fetchCaluclateInvoiceData({ ...VoyageSearchFilters, externalRefId, promoCode });
    }

    if (data.isSellLimitExceeded || data.isSoldOut) {
      dispatch(
        calculateInvoiceErrorAction({
          cabinCategoryCode: VoyageSearchFilters?.cabinCategoryCode,
          cabinType: VoyageSearchFilters?.cabinType,
          isSellLimitExceeded: data.isSellLimitExceeded,
          isSoldOut: data.isSoldOut,
          message: '',
        }),
      );

      // If referred booker flow, have to stay on summary page and display modal window
      // instead of silent redirect

      if (selectConfigServiceFeatureCelebrations(state) && state.celebrations) {
        const { params } = state.celebrations;
        if (params.referralCode !== null) {
          return data;
        }
      }
      const { isReferredBooker } = selectMgm(state);
      if (isReferredBooker) {
        return data;
      }

      navigateToChooseCabinSubMeta(VoyageSearchFilters?.cabinCategoryCode);
      return;
    }

    dispatch({
      payload: data,
      type: SUMMARY_CALCULATE_INVOICE_SUCCESS,
    });
    setSessionStorageValue('invoiceData', data);
    const cabinNumber = getCabinInvoiceNumber(data);
    setSessionStorageValue('cabinNumber', cabinNumber);
  } catch (err) {
    const { data, status } = err.response || {};
    if (data?.code === CalculateInvoiceFailureCode.SEAWEAR_SAILOR_NOT_PERMITTED) {
      routes.planner.dnsError.go();
      return;
    }
    if ([500, 502].includes(status) || data?.code === 'INTERNAL_SERVER_ERROR') {
      dispatch(throwError(err));
      return;
    }

    dispatch(calculateInvoiceErrorAction(err));
    navigateToChooseCabinSubMeta(VoyageSearchFilters?.cabinCategoryCode);
  }
  return data;
};

export default fetchCalculateInvoiceDataAction;
