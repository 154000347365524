import type { AxiosError, AxiosResponse } from 'axios';

import { buildFetchMetaCategoryDataArgs } from '@/actions/voyagePlanner/chooseCabin/fetchMetaCabinCategories';
import { routes } from '@/ducks/routes';
import { setWarningAnyCabinsExhausted, setWarningCabinCategoryExhausted } from '@/features/warnings/actions';
import { fetchMetaCategoryData } from '@/helpers/api/app';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { BookNowFailureCode, type TApiErrorData } from '@/infra/types/api/common';
import { type TMetaCabinCategory } from '@/infra/types/cabin';
import { rootStore } from '@/store';
import { type TOptional } from '@/types/common';

const isEquivalentInTokens = (pattern: string, value: TOptional<string>): TOptional<boolean> => {
  if (value && pattern) {
    const reTokens = /[^a-z0-9]+/gi;
    const [a, b] = [value, pattern].map((str) => str.replace(reTokens, ' ').trim().toLowerCase());
    return a!.startsWith(b!);
  }
};

const CATCHABLE_EXHAUST_ERROR = {
  code: BookNowFailureCode.CABIN_EXHAUSTED,
  message: 'Seaware Error: 323: The booking is waitlisted for one or more Selling limit',
};

const bookNowExhaustCatcher = async (error: AxiosError): Promise<TOptional<boolean>> => {
  const { data, status } = error?.response ?? ({} as AxiosResponse);
  if (status < 400) return;

  const { code, message } = (data ?? {}) as TApiErrorData;
  if (CATCHABLE_EXHAUST_ERROR.code === code || isEquivalentInTokens(CATCHABLE_EXHAUST_ERROR.message, message)) {
    const { dispatch, getState } = rootStore!;
    const parsedParams = searchParamsAsObject(getCurrentSearchParams()) ?? {};
    let metaCategories;
    try {
      metaCategories = await fetchMetaCategoryData(...buildFetchMetaCategoryDataArgs(getState()));
    } catch {
      /* nothing */
    }
    const hasAnyCabins = metaCategories?.some((metaCategory: TMetaCabinCategory) => metaCategory?.isAvailable);
    if (hasAnyCabins) {
      dispatch(setWarningCabinCategoryExhausted(true));
      routes.planner.chooseCabin.go({ keepSearch: true });
    } else {
      dispatch(setWarningAnyCabinsExhausted(true));
      const { voyageId: _, ...params } = parsedParams;
      routes.planner.chooseVoyage.go(params);
    }
    return true;
  }
};

export default bookNowExhaustCatcher;
