import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  META_CABIN_CATEGORY_DATA_ERROR,
  META_CABIN_CATEGORY_DATA_LOADING,
  META_CABIN_CATEGORY_DATA_SUCCESS,
  META_CABIN_CATEGORY_SET_UNAVAILABLE,
} from '@/constants/actionTypes';
import { selectSupportedCurrencies } from '@/ducks/common/lookup/selectors';
import { selectAccessible, selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { fetchMetaCategoryData } from '@/helpers/api/app';
import { selectMetaCategoriesFetchPayload } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories/selectors';

export const buildFetchMetaCategoryDataArgs = (state) => {
  const parameters = {
    accessible: selectAccessible(state),
    currencyCode: selectCurrencyCode(state),
    sailors: selectSailors(state),
  };
  const validateAccessKeyData = get(state, 'accessKeys.activeAccessKey', {});
  const supportedCurrencies = selectSupportedCurrencies(state);
  return [
    {
      ...parameters,
      promoCode:
        !isEmpty(validateAccessKeyData) && get(validateAccessKeyData, 'filterSailing', false)
          ? get(validateAccessKeyData, 'promoCode', '')
          : undefined,
    },
    supportedCurrencies,
  ];
};

const setMetaCabinCategories =
  ({ fetchPayload, metaCabinCategories }) =>
  (dispatch, getState) => {
    dispatch({
      payload: { chooseCabinSort: getState().filters?.chooseCabinSort, fetchPayload, metaCabinCategories },
      type: META_CABIN_CATEGORY_DATA_SUCCESS,
    });
  };

export const fetchMetaCabinCategories =
  ({ voyageId }) =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      const [params, supportedCurrencies] = buildFetchMetaCategoryDataArgs(state);
      const fetchPayload = { ...params, voyageId };

      if (isEqual(selectMetaCategoriesFetchPayload(state), fetchPayload)) {
        return;
      }

      dispatch({ type: META_CABIN_CATEGORY_DATA_LOADING });

      let metaCabinCategories = {};
      if (voyageId) {
        metaCabinCategories = await fetchMetaCategoryData(fetchPayload, supportedCurrencies);
        dispatch(setMetaCabinCategories({ fetchPayload, metaCabinCategories }));
      }
      return metaCabinCategories;
    } catch (err) {
      const { message } = err;
      dispatch({
        payload: { message },
        type: META_CABIN_CATEGORY_DATA_ERROR,
      });
    }
  };

export const setMetaCabinCategoryUnavailableAction = (unavailableMetaCabinCategoryCode) => (dispatch, getState) => {
  dispatch({
    payload: { chooseCabinSort: getState().filters?.chooseCabinSort, unavailableMetaCabinCategoryCode },
    type: META_CABIN_CATEGORY_SET_UNAVAILABLE,
  });
};

export default {
  fetchMetaCabinCategories,
  setMetaCabinCategoryUnavailableAction,
};
